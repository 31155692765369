import { FC, Fragment, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { FormControl, InputLabel, MenuItem, Select, Pagination, Typography, Grid, Paper, Rating, Button, FormControlLabel, Checkbox } from '@mui/material';

import { useTranslation } from 'react-i18next';
import { ProductGrid } from '../components/ProductGrid';
import { getProductList } from '../api/firebase';
import usePagination from '../utils/hooks';

const MainContainer = styled('section')(({ theme }) => ({
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.black,
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridAutoRows: 'max-content',
    gridRowGap: '1rem',
    padding: '1.75rem'
}));

const StyledCatalogTitle = styled(Typography)(({ theme }) => ({
    color: `${theme.palette.primary.main} !important`,
    fontFamily: `${theme.typography.fontFamily} !important`,
    fontSize: "2.5rem",
    fontWeight: `${600} !important`,
    marginBottom: '1.5rem'
}));

const StyledFilterCardTitle = styled(Typography)(({ theme }) => ({
    color: `${theme.palette.primary.main} !important`,
    fontFamily: `${theme.typography.fontFamily} !important`,
    fontSize: "1rem",
    fontWeight: `${600} !important`,
}));

const StyledFilterOption = styled(Typography)(({ theme }) => ({
    color: `${theme.palette.primary.main} !important`,
    fontFamily: `${theme.typography.fontFamily} !important`,
    fontSize: "0.75rem",
    fontWeight: `${400} !important`,
}));

const StyledFilterPaperContainer = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    minHeight: '3rem',
    minWidth: '3rem',
    padding: '1rem'
}));

export const CatalogPage: FC<{}> = () => {
    const { t } = useTranslation('global');

    const [productList, setProductist] = useState<any>([]);
    const [orderByProp, setOrderByProp] = useState<any>('price');
    const [orderDir, setOrderDir] = useState<any>('asc');

    useEffect(() => {
        getProductList(orderByProp, orderDir).then(ss => {
            setProductist(ss.docs.map(doc => doc.data()));
        });
    }, [orderByProp, orderDir]);

    const paginatedList = usePagination(productList, 5);

    const handleOrderByChange = (e: any) => setOrderByProp(e.target.value);
    const handleOrderDirChange = (e: any) => setOrderDir(e.target.value);

    const handleChange = (e: any, p: number) => { 
        paginatedList.jumpToPage(p);
    };

    return (
        <Fragment>
            <MainContainer>
                <StyledCatalogTitle>{t('catalogPage.title')}</StyledCatalogTitle>
                
                <Grid container>
                    <Grid
                        alignItems={'right'}
                        container
                        item
                        direction={'column'}
                        rowSpacing={3}
                        sx={{ paddingRight: '1rem' }}
                        xs={2} md={2} xl={2}
                    >
                        <Grid item>
                            <StyledFilterPaperContainer elevation={3} square={false}>
                                <StyledFilterCardTitle>{t('catalogPage.labels.filterTitleCategory')}</StyledFilterCardTitle>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <FormControlLabel control={<Checkbox />} label={t('sideBarTitles.computersAndLaptops')} />
                                    <FormControlLabel control={<Checkbox />} label={t('sideBarTitles.hardware')} />
                                    <FormControlLabel control={<Checkbox />} label={t('sideBarTitles.printingAndConsumables')} />
                                    <FormControlLabel control={<Checkbox />} label={t('sideBarTitles.electronics')} />
                                    <FormControlLabel control={<Checkbox />} label={t('sideBarTitles.networks')} />
                                    <FormControlLabel control={<Checkbox />} label={t('sideBarTitles.power')} />
                                </div>
                            </StyledFilterPaperContainer>
                        </Grid>
                        <Grid item>
                            <StyledFilterPaperContainer elevation={3} square={false}>
                                <StyledFilterCardTitle>{t('catalogPage.labels.filterTitleRating')}</StyledFilterCardTitle>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'row' }}>
                                        <Rating value={5} precision={1} readOnly />
                                        <StyledFilterOption>{t('catalogPage.labels.filterOption5Star')}</StyledFilterOption>
                                    </div>
                                    <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'row' }}>
                                        <Rating value={4} precision={1} readOnly />
                                        <StyledFilterOption>{t('catalogPage.labels.filterOption4Star')}</StyledFilterOption>
                                    </div>
                                    <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'row' }}>
                                        <Rating value={3} precision={1} readOnly />
                                        <StyledFilterOption>{t('catalogPage.labels.filterOption3Star')}</StyledFilterOption>
                                    </div>
                                    <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'row' }}>
                                        <Rating value={2} precision={1} readOnly />
                                        <StyledFilterOption>{t('catalogPage.labels.filterOption2Star')}</StyledFilterOption>
                                    </div>
                                    <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'row' }}>
                                        <Rating value={1} precision={1} readOnly />
                                        <StyledFilterOption>{t('catalogPage.labels.filterOption1Star')}</StyledFilterOption>
                                    </div>
                                </div>
                            </StyledFilterPaperContainer>
                        </Grid>
                        <Grid item>
                            <StyledFilterPaperContainer elevation={3} square={false}>
                                <StyledFilterCardTitle>{t('catalogPage.labels.filterTitlePrice')}</StyledFilterCardTitle>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Button variant="outlined">{'< $350'}</Button>
                                    <Button variant="outlined">{'$350 - $750'}</Button>
                                    <Button variant="outlined">{'$750 - $1,500'}</Button>
                                    <Button variant="outlined">{'$1,500 - $3,000'}</Button>
                                    <Button variant="outlined">{'> $3,000'}</Button>
                                </div>
                            </StyledFilterPaperContainer>
                        </Grid>
                        <Grid item>
                            <StyledFilterPaperContainer elevation={3} square={false}>
                                <StyledFilterCardTitle>{t('catalogPage.labels.filterTitleBrand')}</StyledFilterCardTitle>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <FormControlLabel control={<Checkbox />} label={'Lenovo'} />
                                    <FormControlLabel control={<Checkbox />} label={'Dell'} />
                                    <FormControlLabel control={<Checkbox />} label={'HP'} />
                                    <FormControlLabel control={<Checkbox />} label={'Acer'} />
                                    <FormControlLabel control={<Checkbox />} label={'MSI'} />
                                </div>
                            </StyledFilterPaperContainer>
                        </Grid>
                    </Grid>

                    <Grid container item xs={10} md={10} xl={10} rowSpacing={4}>
                        <Grid container item spacing={3}>
                            <Grid item xs={2} md={2} xl={2}>
                                <FormControl variant={'outlined'} fullWidth>
                                    <InputLabel id="orderBySelectLabel">{t('catalogPage.labels.orderByLabel')}</InputLabel>
                                    <Select
                                        id="orderBySelect"
                                        label={t('catalogPage.labels.orderByLabel')}
                                        labelId="orderBySelectLabel"
                                        onChange={e => handleOrderByChange(e)}
                                        value={orderByProp}
                                    >
                                        <MenuItem key={`orderByOption-price`} value={'price'}>{t('catalogPage.labels.orderByPrice')}</MenuItem>
                                        <MenuItem key={`orderByOption-rating`} value={'rating'}>{t('catalogPage.labels.orderByRating')}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={2} md={2} xl={2}>
                                <FormControl variant={'outlined'} fullWidth>
                                    <InputLabel id="orderDirSelectLabel">{t('catalogPage.labels.orderDirectionLabel')}</InputLabel>
                                    <Select
                                        id="orderDirSelect"
                                        label={t('catalogPage.labels.orderDirectionLabel')}
                                        labelId="orderDirSelectLabel"
                                        onChange={e => handleOrderDirChange(e)}
                                        value={orderDir}
                                    >
                                        <MenuItem key={`orderDirOption-asc`} value={'asc'}>{t('catalogPage.labels.orderDirectionAscLabel')}</MenuItem>
                                        <MenuItem key={`orderDirOption-desc`} value={'desc'}>{t('catalogPage.labels.orderDirectionDescLabel')}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container item justifyContent={'center'} spacing={3}>
                            <Grid item>
                                <Pagination count={paginatedList.maxPage} color="primary" onChange={handleChange} />
                            </Grid>
                        </Grid>
                        
                        <Grid container item sx={{ width: '100%' }}>
                            <ProductGrid productList={productList} />
                        </Grid>

                        <Grid container item justifyContent={'center'} spacing={3}>
                            <Grid item>
                                <Pagination count={paginatedList.maxPage} color="primary" onChange={handleChange} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </MainContainer>
        </Fragment>
    );
}