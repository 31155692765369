import { collection, doc, getDoc, getDocs, orderBy, query, setDoc } from 'firebase/firestore';
import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signOut
} from "firebase/auth";

import { auth, db } from '../firebase/firebase';

export const getLogo = async () => {
    const snapRef = await getDoc(doc(db, "images", "technohive-logo"));
    return snapRef.data() as any;
};

export const getIcon = async () => {
    const snapRef = await getDoc(doc(db, "images", "technohive-icon"));
    return snapRef.data() as any;
};

export const getMainSliderImages = async () => {
    const snapRef = await getDoc(doc(db, "images", "slider"));
    return snapRef.data() as any;
};

export const getSectionList = async (listName: string) => {
    const snapRef = await getDoc(doc(db, "sections", listName));
    return snapRef.data() as any;
};

export const getProduct = async (docId: string) => {
    const snapRef = await getDoc(doc(db, "products", docId));
    return snapRef.data() as any;
};

export const getUserByUID = async (uid: string) => {
    const snapRef = await getDoc(doc(db, 'users', uid));
    return snapRef.data() as any;
};

export const createUserInDB = async (uid: any, values: any) => {
    await setDoc(doc(db, 'users', uid), {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email
    });
};

export const getCurrentUserUID = () => {
    return auth.currentUser?.uid;
}

export const editUser = async (data: any, uid: string) => {
    await setDoc(doc(db, 'users', uid), data);
};

export const createUser = async (values: any) => {
    try {
        createUserWithEmailAndPassword(auth, values.email, values.password).then(cred => {
            return createUserInDB(cred.user.uid, values);
        });
    } catch (error: any) {
        console.log(error.message);
    }
};

export const signIn = async (values: any) => {
    try {
        await signInWithEmailAndPassword(auth, values.email, values.password);
    } catch (error: any) {
        console.log(error.message);
    }
};

export const endSession = async () => {
    await signOut(auth);
};

export const getProductList = async (orderFieldName: string, orderDir: string) => {
    const result = await getDocs(query(
        collection(db, 'products'),
        orderBy(orderFieldName, orderDir as any)
    ));
    return result;
};
