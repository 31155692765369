import { Accordion, AccordionDetails, AccordionSummary, Grid, FormControl, InputLabel, OutlinedInput, AccordionActions, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FC } from 'react';
import { Controller, UseFieldArrayRemove } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface ShippingAddressAccordionProps {
    arrayName: string;
    control: any;
    field: any;
    id: string;
    index: number;
    remove: UseFieldArrayRemove;
}

export const ShippingAddressAccordion: FC<ShippingAddressAccordionProps> = ({ arrayName, control, field, id, index, remove }) => {
    const { t } = useTranslation('global');

    return (
        <Accordion key={id}>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={'panel1a-content'}
                id={'panel1a-header'}
            >
                { `${t('userPage.shippingDataTitles.title')}${index + 1}`}
            </AccordionSummary>
            <AccordionDetails>
                <Grid container justifySelf={'center'} spacing={3}>
                    <Grid item xs={4} md={4} xl={4}>
                        <Controller
                            control={control}
                            name={`${arrayName}[${index}].streetName`}
                            render={({ field: { onChange, value, name }  }) =>
                                <FormControl variant={'outlined'} fullWidth>
                                    <InputLabel htmlFor="streetName">{t('userPage.addressDataFields.streetName')}</InputLabel>
                                    <OutlinedInput
                                        id={'street'}
                                        label={t('userPage.addressDataFields.streetName')}
                                        name={name}
                                        onChange={(e) => onChange(e)}
                                        value={value}
                                    />
                                </FormControl>
                            }
                        />
                    </Grid>
                    <Grid item xs={2} md={2} xl={2}>
                        <Controller
                            control={control}
                            name={`${arrayName}[${index}].streetNumExt`}
                            render={({ field: { onChange, value, name }  }) =>
                                <FormControl variant={'outlined'} fullWidth>
                                    <InputLabel htmlFor="streetNumberExt">{t('userPage.addressDataFields.streetNumberExt')}</InputLabel>
                                    <OutlinedInput
                                        id={'streetNumberExt'}
                                        label={t('userPage.addressDataFields.streetNumberExt')}
                                        name={name}
                                        onChange={(e) => onChange(e)}
                                        value={value}
                                    />
                                </FormControl>
                            }
                        />
                    </Grid>
                    <Grid item xs={2} md={2} xl={2}>
                        <Controller
                            control={control}
                            name={`${arrayName}[${index}].streetNumInt`}
                            render={({ field: { onChange, value, name }  }) =>
                                <FormControl variant={'outlined'} fullWidth>
                                    <InputLabel htmlFor="streetNumberInt">{t('userPage.addressDataFields.streetNumberInt')}</InputLabel>
                                    <OutlinedInput
                                        id={'streetNumberInt'}
                                        label={t('userPage.addressDataFields.streetNumberInt')}
                                        name={name}
                                        onChange={(e) => onChange(e)}
                                        value={value}
                                    />
                                </FormControl>
                            }
                        />
                    </Grid>
                    <Grid item xs={4} md={4} xl={4}>
                        <Controller
                            control={control}
                            name={`${arrayName}[${index}].neighborhood`}
                            render={({ field: { onChange, value, name }  }) =>
                                <FormControl variant={'outlined'} fullWidth>
                                    <InputLabel htmlFor="neighborhood">{t('userPage.addressDataFields.neighborhood')}</InputLabel>
                                    <OutlinedInput
                                        id={'neighborhood'}
                                        label={t('userPage.addressDataFields.neighborhood')}
                                        name={field.name}
                                        onChange={(e) => onChange(e)}
                                        value={value}
                                    />
                                </FormControl>
                            }
                        />
                    </Grid>
                    <Grid item xs={3} md={3} xl={3}>
                        <Controller
                            control={control}
                            name={`${arrayName}[${index}].zipCode`}
                            render={({ field: { onChange, value, name }  }) =>
                                <FormControl variant={'outlined'} fullWidth>
                                    <InputLabel htmlFor="zipCode">{t('userPage.addressDataFields.zipCode')}</InputLabel>
                                    <OutlinedInput
                                        id={'zipCode'}
                                        label={t('userPage.addressDataFields.zipCode')}
                                        name={name}
                                        onChange={(e) => onChange(e)}
                                        value={value}
                                    />
                                </FormControl>
                            }
                        />
                    </Grid>
                    <Grid item xs={3} md={3} xl={3}>
                        <Controller
                            control={control}
                            name={`${arrayName}[${index}].locality`}
                            render={({ field: { onChange, value, name }  }) =>
                                <FormControl variant={'outlined'} fullWidth>
                                    <InputLabel htmlFor="locality">{t('userPage.addressDataFields.locality')}</InputLabel>
                                    <OutlinedInput
                                        id={'locality'}
                                        label={t('userPage.addressDataFields.locality')}
                                        name={name}
                                        onChange={(e) => onChange(e)}
                                        value={value}
                                    />
                                </FormControl>
                            }
                        />
                    </Grid>
                    <Grid item xs={3} md={3} xl={3}>
                        <Controller
                            control={control}
                            name={`${arrayName}[${index}].municipality`}
                            render={({ field: { onChange, value, name }  }) =>
                                <FormControl variant={'outlined'} fullWidth>
                                    <InputLabel htmlFor="municipality">{t('userPage.addressDataFields.municipality')}</InputLabel>
                                    <OutlinedInput
                                        id={'municipality'}
                                        label={t('userPage.addressDataFields.municipality')}
                                        name={name}
                                        onChange={(e) => onChange(e)}
                                        value={value}
                                    />
                                </FormControl>
                            }
                        />
                    </Grid>
                    <Grid item xs={3} md={3} xl={3}>
                        <Controller
                            control={control}
                            name={`${arrayName}[${index}].state`}
                            render={({ field: { onChange, value, name }  }) =>
                                <FormControl variant={'outlined'} fullWidth>
                                    <InputLabel htmlFor="state">{t('userPage.addressDataFields.state')}</InputLabel>
                                    <OutlinedInput
                                        id={'state'}
                                        label={t('userPage.addressDataFields.state')}
                                        name={name}
                                        onChange={(e) => onChange(e)}
                                        value={value}
                                    />
                                </FormControl>
                            }
                        />
                    </Grid>
                </Grid>
            </AccordionDetails>
            <AccordionActions>
                <Button 
                    onClick={() => remove(index)}
                    color={'error'}
                    variant="outlined"
                >
                    {t('userPage.deleteShippingAddressButton')}
                </Button>
            </AccordionActions>
        </Accordion>
    );
};
