import { Typography, Button } from "@mui/material";
import { styled } from '@mui/system';

import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';

import { useEffect, FC } from "react";
import { useGlobal } from "../context/GlobalContext";
import { LongProductPreview } from "../components/LongProductPreview";
import { numberWithCommas } from "../utils";
import { useTranslation } from "react-i18next";

const MainContainer = styled('div')({
    alignItems: "center",
    display: "grid",
    gridTemplateColumns: "1fr",
    gap: "2rem",
    height: '60vh',
    justifyItems: "center",
    marginBottom: "9.375rem",
    marginTop: "9.375rem",
    padding: "0 5.25rem",
    position: "relative",
    width: "100vw",
});

const MainEmptyContainer = styled('div')({
    alignItems: "center",
    display: "grid",
    gridTemplateColumns: "1fr",
    gap: "2rem",
    height: '60vh',
    justifyItems: "center",
    marginBottom: "9.375rem",
    marginTop: "9.375rem",
    padding: "0 5.25rem",
    position: "relative",
});

const StyledPageTitle = styled(Typography)(({ theme }) => ({
    color: `${theme.palette.primary.main} !important`,
    fontSize: "2.5rem",
    fontWeight: `${600} !important`,
    marginBottom: '1.5rem'
}));

const MainHeader = styled('div')({
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: '80vw'
});

const ButtonContainer = styled('div')({
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    height: "auto",
    width: '80vw'
});

const StyledEmptyCartButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.common.black,
    fontFamily: "SamsungOne",
    fontSize: "1rem",
    fontWeight: 600,
    padding: "0.813rem",
    textTransform: "capitalize",
    width: "12.75rem",
    "&:hover": {
        backgroundColor: "#a1a1a1",
    },
}))

const StyledPayButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    fontFamily: "SamsungOne",
    fontSize: "1rem",
    fontWeight: 600,
    padding: "0.813rem",
    textTransform: "capitalize",
    width: "12.75rem",
    "&:hover": {
        backgroundColor: "#a1a1a1",
    },
}))

const EmptyCartView: FC<{}> = () => {
    const { t } = useTranslation('global');

    return (
        <div
            style={{
                alignItems: "center",
                display: "grid",
                gridTemplateRows: "2fr 1fr",
                justifyItems: "center",
                padding: '4rem',
                position: "relative",
            }}
        >
            <ProductionQuantityLimitsIcon
                sx={(theme) => ({ color: theme.palette.primary.main, fontSize: "10rem" })}
            />
            <Typography
                sx={(theme) => ({
                    color: theme.palette.primary.main,
                    fontSize: "2.25rem",
                    fontWeight: 700,
                })}
                variant={"h1"}
            >
                {t('cartPage.cartIsEmpty')}
            </Typography>
        </div>
    );
};

const CartPageView: FC<{}> = () => {
    const { state, emptyCart, getCartTotalPrice } = useGlobal();
    const { t } = useTranslation('global');

    return (
        <>
            {state.cart.length ? (
                <MainContainer>
                    <MainHeader>
                        <StyledPageTitle variant={"h1"}>
                            {t('cartPage.title')}
                        </StyledPageTitle>
                        <Typography
                            sx={(theme) => ({
                                color: theme.palette.primary.main,
                                fontSize: "2.25rem",
                                fontWeight: 700,
                                whiteSpace: "nowrap",
                            })}
                            variant={"h1"}
                        >
                            {`$${numberWithCommas(getCartTotalPrice())}`}
                        </Typography>
                    </MainHeader>
                    {
                        state.cart.map((item: any, index: number) => (
                            <LongProductPreview product={item} index={index} />
                        ))
                    }
                    <ButtonContainer>
                        <StyledEmptyCartButton
                            onClick={emptyCart}
                            variant={"contained"}
                            startIcon={<RemoveShoppingCartIcon />}
                        >
                            {t('cartPage.emptyCartButton')}
                        </StyledEmptyCartButton>
                        <StyledPayButton
                            onClick={() => { }}
                            variant={"contained"}
                            startIcon={<AttachMoneyIcon />}
                        >
                            {t('cartPage,payCartButton')}
                        </StyledPayButton>
                    </ButtonContainer>
                </MainContainer>
            ) : (
                <MainEmptyContainer>
                    <EmptyCartView />
                </MainEmptyContainer>
            )}
        </>
    );
};

export const CartPage: FC<{}> = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <CartPageView />
    );
};
