import { FC, Fragment } from 'react';
import { styled } from '@mui/material/styles';

const MainContainer = styled('section')(({ theme }) => ({
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.black,
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridAutoRows: 'max-content',
    gridRowGap: '1rem',
    padding: '1.75rem'
}));

export const SuccesfulPaymentPage: FC<{}> = () => {

    return (
        <Fragment>
            <MainContainer>
                SuccesfulPayment
            </MainContainer>
        </Fragment>
    );
}