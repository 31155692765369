import { FC, ReactNode, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import AccountCircle from '@mui/icons-material/AccountCircle';
import CartIcon from '@mui/icons-material/ShoppingCart';
import CableIcon from '@mui/icons-material/Cable';
import ComputerIcon from '@mui/icons-material/Computer';
import KeyboardIcon from '@mui/icons-material/Keyboard';
import MenuIcon from '@mui/icons-material/Menu';
import PowerIcon from '@mui/icons-material/Power';
import PrintIcon from '@mui/icons-material/Print';
import RouterIcon from '@mui/icons-material/Router';

import { AppBar, Badge, Button, Drawer, Divider, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, Toolbar } from '@mui/material';
import { styled } from '@mui/material/styles';

import { getLogo } from '../api/firebase';
import { useGlobal } from '../context/GlobalContext';
import { useAuth } from '../context/AuthContext';

const StyledAppbar = styled(AppBar)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    position: "sticky" as "sticky",
    top: 0,
    width: "100%"
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.secondary.main,
    mr: 2
}));

const StyledDrawer = styled(Drawer)(({ theme }) => ({
    '& .MuiDrawer-paper': {
        backgroundColor: theme.palette.primary.main,
    }
}));

const StyledDrawerItemText = styled(ListItemText)(({ theme }) => ({
    color: theme.palette.secondary.main
}));

const StyledDrawerButton = styled(Button)(({ theme }) => ({
    color: theme.palette.secondary.main
}));

const StyledDrawerItemIcon = styled(ListItemIcon)(({ theme }) => ({
    color: theme.palette.secondary.main
}));

const StyledDrawerIconButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.secondary.main
}));

const StyledBadge = styled(Badge)(() => ({
    color: '#ff0000'
}));

const DrawerOptions: { name: string, icon: ReactNode }[] = [
    { name: 'computersAndLaptops', icon: <ComputerIcon /> },
    { name: 'hardware', icon: <KeyboardIcon /> },
    { name: 'printingAndConsumables', icon: <PrintIcon /> },
    { name: 'electronics', icon: <CableIcon /> },
    { name: 'networks', icon: <RouterIcon /> },
    { name: 'power', icon: <PowerIcon /> },
];

export const Navbar: FC<{}> = () => {
    const { user, signOut } = useAuth();
    const { changeLanguage, state } = useGlobal();
    const navigate = useNavigate();
    const { t } = useTranslation('global');

    const [langEs, setLangEs] = useState(true);
    const [logo, setLogo] = useState('');
    const [openDrawer, setOpenDrawer] = useState(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    useEffect(() => {
        (async () => {
            const data = await getLogo();
            setLogo(data.url);
        })();
    }, []);

    useEffect(() => {
        changeLanguage(!langEs ? 'en' : 'es');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [langEs]);

    const toggleDrawer = () => setOpenDrawer(prev => !prev);

    const handleCatalogClick = () => navigate('/catalog');

    const handleUserClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);

    const handleClose = () => setAnchorEl(null);

    return (
        <StyledAppbar>
            <Toolbar sx={{ dispplay: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                    <StyledIconButton onClick={toggleDrawer} size="large" edge="start" aria-label="open drawer">
                        <MenuIcon />
                    </StyledIconButton>
                    <StyledDrawer
                        anchor={'left'}
                        open={openDrawer}
                        onClose={toggleDrawer}
                    >
                        <Toolbar />
                        <Divider />
                        <List>
                            {DrawerOptions.map((item: { name: string, icon: ReactNode }, index: number) => (
                                <ListItem key={index} disablePadding>
                                    <ListItemButton>
                                        <StyledDrawerItemIcon>
                                            {item.icon}
                                        </StyledDrawerItemIcon>
                                        <StyledDrawerItemText primary={t(`sideBarTitles.${item.name}`)} />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    </StyledDrawer>
                    <Link to={'/'}>
                        <img alt={'technohive-logo'} src={logo} height={40} width={200}/>
                    </Link>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                    <StyledDrawerButton onClick={handleCatalogClick}>
                        {`${t('buttons.catalogButton')}`}
                    </StyledDrawerButton>
                    <StyledDrawerButton>
                        {t('buttons.contactButton')}
                    </StyledDrawerButton>
                    <StyledDrawerIconButton
                        size="large"
                        aria-label="language button"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        color="inherit"
                        onClick={() => setLangEs(prev => !prev)}
                    >
                        {
                            langEs ? (
                                <img
                                    loading="lazy"
                                    width="20"
                                    srcSet={`https://flagcdn.com/w40/mx.png 2x`}
                                    src={`https://flagcdn.com/w40/mx.png`}
                                    alt="MX"
                                />
                            ) : (
                                <img
                                    loading="lazy"
                                    width="20"
                                    srcSet={`https://flagcdn.com/w40/us.png 2x`}
                                    src={`https://flagcdn.com/w40/us.png`}
                                    alt="USA"
                                />
                            )
                        }
                    </StyledDrawerIconButton>
                    
                    <StyledBadge badgeContent={state.cart.reduce((sum) => sum + 1, 0)} color="secondary">
                        <StyledDrawerIconButton
                            size="large"
                            aria-label="user shopping cart"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            color="inherit"
                            onClick={() => navigate('/cart')}
                        >
                            <CartIcon />
                        </StyledDrawerIconButton>
                    </StyledBadge>
                    { user ? (
                        <StyledDrawerButton onClick={handleUserClick}>
                            {`${t('greetings.helloUser')} ${user['firstName']}`}
                        </StyledDrawerButton>
                    )
                        : (
                            <StyledDrawerIconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                color="inherit"
                                onClick={handleUserClick}
                            >
                                <AccountCircle />
                            </StyledDrawerIconButton>
                        )
                    }
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        {
                            user ? (
                                <>
                                    <MenuItem onClick={() => {
                                        navigate('/my-account');
                                        handleClose();
                                    }}>
                                        { t('userMenuOptions.myAccount') }
                                    </MenuItem>
                                    <MenuItem onClick={() => {
                                        signOut();
                                        window.location.reload();
                                    }}>
                                        { t('userMenuOptions.logout') }
                                    </MenuItem>
                                </>
                            ) 
                            : (
                                <>
                                    <MenuItem onClick={() => {
                                        navigate('/login');
                                        handleClose();
                                    }}>
                                        { t('userMenuOptions.login') }
                                    </MenuItem>
                                    <MenuItem onClick={() => {
                                        navigate('/signup');
                                        handleClose();
                                    }}>
                                        { t('userMenuOptions.signUp') }
                                    </MenuItem>
                                </>
                            )
                        }
                    </Menu>
                </div>
            </Toolbar>
        </StyledAppbar>
    );
}
