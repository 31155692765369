import { Card, CardContent, CardMedia, Rating, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import { FC } from 'react';
import { useNavigate } from "react-router-dom";

import { numberWithCommas } from "../utils";

const StyledCard = styled(Card)(({ theme }) => ({
    alignItems: "center",
    border: `0.063rem ${theme.palette.primary.main} solid`,
    display: "flex",
    flexDirection: "row",
    height: "10.188rem",
    justifyContent: 'flex-start',
    padding: "1.125rem",
    "&.MuiPaper-root-MuiCard-root": {
        
    },
    "&:hover": {
        boxShadow: `inset 0 1px 16px ${theme.palette.primary.main}`,
        cursor: 'pointer'
    },
}));

const StyledTypographyTitle = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main,
    fontSize: "1.125rem",
    fontWeight: 600,
}));

const StyledTypographyPrice = styled(Typography)(({ theme }) => ({
    color: theme.palette.common.black,
    fontSize: "1rem",
    fontWeight: 400,
}));

export const LongProductPreview: FC<{product: any, index: number}> = ({ product, index }) => {
    const navigate = useNavigate();

    const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, productId: string) => {
        e.preventDefault();
        navigate(`/product/${productId}`);
    };

    return (
        <StyledCard key={`product-grid-option-${index}`} onClick={e => handleClick(e, product.id)}>
            <CardMedia
                alt="product-img"
                component="img"
                height="160"
                image={product.thumbnail}
                sx={{ objectFit: 'contain', maxWidth: '10rem' }}
                width="160"
            />
            <CardContent sx={{ alignItems: 'flex-start', display: 'flex', flexDirection: 'column', paddingLeft: '1rem' }}>
                <StyledTypographyTitle>{product.name}</StyledTypographyTitle>
                <StyledTypographyPrice>{`$ ${numberWithCommas(parseFloat(product.price))}`}</StyledTypographyPrice>
                <Rating value={product.rating || 0.0} precision={0.5} readOnly />
            </CardContent>
        </StyledCard>
    );
};