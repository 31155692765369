import { FC, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import { Box, Button, Checkbox, Container, CssBaseline, FormControlLabel, Grid, TextField, Typography } from '@mui/material';

import { getIcon } from '../api/firebase';
import { Copyright } from '../components/Copyright';
import { useAuth } from '../context/AuthContext';

interface LoginPageFields {
    email: string;
    password: string;
}

export const LoginPage: FC<{}> = () => {
    const { t } = useTranslation('global');
    const { logIn } = useAuth();
    const navigate = useNavigate();
    const { control, handleSubmit } = useForm<LoginPageFields>({
        defaultValues: {
            email: '',
            password: ''
        }
    });

    const [logo, setLogo] = useState('');

    useEffect(() => {
        getIcon().then(data => setLogo(data.url));
    }, []);

    const onSubmit: SubmitHandler<LoginPageFields> = (data) => {
        logIn(data);
        navigate('/');
    };

    return (
        <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center' }}>
            <Container
                component="main"
                maxWidth="xs"
                sx={(theme) => ({
                    backgroundColor: theme.palette.common.white,
                    borderRadius: '0.375rem',
                    boxShadow: '2px 2px 5px #d3d3d3',
                    padding: '1.5rem 1rem'
                })}
            >
                <CssBaseline />
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <img alt={'technohive-icon'} src={logo} height={160} width={160} />
                    <Typography component="h1" variant="h5">
                        {t('loginPage.title')}
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1 }}>
                        <Controller
                            control={control}
                            name={'email'}
                            render={({ field }) =>
                                <TextField
                                    {...field}
                                    autoComplete="email"
                                    autoFocus
                                    fullWidth
                                    label={t('loginPage.emailField')}
                                    margin="normal"
                                />
                            }
                        />
                        <Controller
                            control={control}
                            name={'password'}
                            render={({ field }) =>
                                <TextField
                                    {...field}
                                    autoComplete="current-password"
                                    fullWidth
                                    label={t('loginPage.passwordField')}
                                    margin="normal"
                                    type="password"
                                />
                            }
                        />
                        <FormControlLabel
                            control={<Checkbox value="remember" color="primary" />}
                            label={t('loginPage.rememberMeLabel')}
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            {t('loginPage.logInButton')}
                        </Button>
                        <Grid container direction={'column'}>
                            <Grid item>
                                <Link to="/signup">
                                    {t('loginPage.notHaveAccountLabel')}
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <Copyright sx={{ mt: 8, mb: 4 }} />
            </Container>
        </div>
    );
};
