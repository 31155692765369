import { Box, Button, CircularProgress, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { FC, useEffect } from 'react';
import { Controller, SubmitHandler, useForm, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { editUser, getCurrentUserUID } from '../api/firebase';
import { useAuth } from '../context/AuthContext';
import { ShippingAddressAccordion } from '../components/ShippingAddressAccordion';
import { useSnackbar } from '../context/SnackbarContext';

const MainContainer = styled('section')(({ theme }) => ({
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.black,
    padding: '1.75rem'
}));

const StyledUserTitle = styled(Typography)(({ theme }) => ({
    color: `${theme.palette.primary.main} !important`,
    fontFamily: `${theme.typography.fontFamily} !important`,
    fontSize: "2.5rem",
    fontWeight: `${600} !important`,
    marginBottom: '1.5rem'
}));

const StyledUserSubtitle = styled(Typography)(({ theme }) => ({
    color: `${theme.palette.primary.main} !important`,
    fontFamily: `${theme.typography.fontFamily} !important`,
    fontSize: "1.5rem",
    fontWeight: `${600} !important`,
}));

interface FiscalRegime {
    value: string;
}

const FISCAL_REGIMES_LIST: FiscalRegime[] = [
    { value: 'GLPM-601' },
    { value: 'PMFL-603' },
    { value: 'SSIAS-605' },
    { value: 'ARRE-606' },
    { value: 'REAB-607' },
    { value: 'DEIN-608' },
    { value: 'REEPM-610' },
    { value: 'INDIV-611' },
    { value: 'PFAEP-612' },
    { value: 'INGINT-614' },
    { value: 'RIOP-615' },
    { value: 'NONE-616' },
    { value: 'SCPODEI-620' },
    { value: 'INFI-621' },
    { value: 'AAGSP-622' },
    { value: 'OGS-623' },
    { value: 'COORD-624' },
    { value: 'RAEITPT-625' },
    { value: 'RESICO-626' }
];

interface AddressDataFields {
    streetName: string;
    streetNumExt: string;
    streetNumInt: string;
    neighborhood: string;
    zipCode: string;
    locality: string;
    municipality: string;
    state: string;
}

const EMPTY_ADDRESS_DATAFIELDS = {
    streetName: '',
    streetNumExt: '',
    streetNumInt: '',
    neighborhood: '',
    zipCode: '',
    locality: '',
    municipality: '',
    state: ''
};

interface UserContactFields {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    rfc: string;
    fiscalRegime: string;
    billingAddress: AddressDataFields;
    shippingAddresses: AddressDataFields[];
}

export const UserPage: FC<{}> = () => {
    const { user } = useAuth();
    const { openSnackbar } = useSnackbar();
    const { control, handleSubmit, reset } = useForm<UserContactFields>({
        defaultValues: {
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            rfc: '',
            fiscalRegime: '',
            billingAddress: {
                streetName: '',
                streetNumExt: '',
                streetNumInt: '',
                neighborhood: '',
                zipCode: '',
                locality: '',
                municipality: '',
                state: ''
            },
            shippingAddresses: [{
                streetName: '',
                streetNumExt: '',
                streetNumInt: '',
                neighborhood: '',
                zipCode: '',
                locality: '',
                municipality: '',
                state: ''
            }]
        }
    });
    const { fields, append, remove } = useFieldArray({ name: 'shippingAddresses', control });
    const { t } = useTranslation('global');

    useEffect(() => {
        reset(user as any);
    }, [user, reset]);

    const onSubmit: SubmitHandler<UserContactFields> = (data) => {
        const uid = getCurrentUserUID();
        editUser(data, uid as any).then(() => openSnackbar(`${t('userPage.snackbarSuccesfullMessage')}`, 'success'));
    };

    return (
        <MainContainer>
            <StyledUserTitle>{t('userPage.title')}</StyledUserTitle>

            <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={( theme ) => ({
                [theme.breakpoints.up('lg')]: {
                    padding: '0 18rem'
                },
                [theme.breakpoints.up('xl')]: {
                    padding: '0 32rem'
                }
            })}>
                <StyledUserSubtitle>{t('userPage.contactData')}</StyledUserSubtitle>
                <Grid container justifyContent={'center'} spacing={3} sx={{ marginBottom: '2rem' }}>
                    <Grid item xs={6} md={6} xl={6}>
                        <Controller
                            control={control}
                            name={'firstName'}
                            render={({ field }) =>
                                <FormControl variant={'outlined'} fullWidth>
                                    <InputLabel htmlFor="firstName">{t('userPage.contactDataFields.firstName')}</InputLabel>
                                    <OutlinedInput
                                        autoComplete="given-name"
                                        autoFocus
                                        {...field}
                                        endAdornment={
                                            !user && (<CircularProgress />)
                                        }
                                        id={'firstName'}
                                        label={t('userPage.contactDataFields.firstName')}
                                    />
                                </FormControl>
                            }
                        />
                    </Grid>
                    <Grid item xs={6} md={6} xl={6}>
                        <Controller
                            control={control}
                            name={'lastName'}
                            render={({ field }) =>
                                <FormControl variant={'outlined'} fullWidth>
                                    <InputLabel htmlFor="lastName">{t('userPage.contactDataFields.lastName')}</InputLabel>
                                    <OutlinedInput
                                        autoComplete="family-name"
                                        {...field}
                                        endAdornment={
                                            !user && (<CircularProgress />)
                                        }
                                        id={'lastName'}
                                        label={t('userPage.contactDataFields.lastName')}
                                    />
                                </FormControl>
                            }
                        />
                    </Grid>
                    <Grid item xs={12} md={12} xl={12}>
                        <Controller
                            control={control}
                            name={'email'}
                            render={({ field }) =>
                                <FormControl variant={'outlined'} fullWidth>
                                    <InputLabel htmlFor="email">{t('userPage.contactDataFields.email')}</InputLabel>
                                    <OutlinedInput
                                        autoComplete="email"
                                        {...field}
                                        endAdornment={
                                            !user && (<CircularProgress />)
                                        }
                                        id={'email'}
                                        label={t('userPage.contactDataFields.email')}
                                    />
                                </FormControl>
                            }
                        />
                    </Grid>
                    <Grid item xs={12} md={12} xl={12}>
                        <Controller
                            control={control}
                            name={'phoneNumber'}
                            render={({ field }) =>
                                <FormControl variant={'outlined'} fullWidth>
                                    <InputLabel htmlFor="phoneNumber">{t('userPage.contactDataFields.phone')}</InputLabel>
                                    <OutlinedInput
                                        autoComplete="tel"
                                        {...field}
                                        endAdornment={
                                            !user && (<CircularProgress />)
                                        }
                                        id={'phoneNumber'}
                                        label={t('userPage.contactDataFields.phone')}
                                        type={'tel'}
                                    />
                                </FormControl>
                            }
                        />
                    </Grid>
                </Grid>

                <StyledUserSubtitle>{t('userPage.billingData')}</StyledUserSubtitle>
                <Grid container justifySelf={'center'} spacing={3} sx={{ marginBottom: '2rem' }}>
                    <Grid item xs={6} md={6} xl={6}>
                        <Controller
                            control={control}
                            name={'rfc'}
                            render={({ field }) =>
                                <FormControl variant={'outlined'} fullWidth>
                                    <InputLabel htmlFor="rfc">{t('userPage.billingDataFields.rfc')}</InputLabel>
                                    <OutlinedInput
                                        {...field}
                                        endAdornment={
                                            !user && (<CircularProgress />)
                                        }
                                        id={'rfc'}
                                        label={t('userPage.billingDataFields.rfc')}
                                    />
                                </FormControl>
                            }
                        />
                    </Grid>
                    <Grid item xs={6} md={6} xl={6}>
                        <Controller
                            control={control}
                            name={'fiscalRegime'}
                            render={({ field }) =>
                                <FormControl variant={'outlined'} fullWidth>
                                    <InputLabel id="fiscalRegimeLabel">{t('userPage.billingDataFields.fiscalRegime')}</InputLabel>
                                    <Select
                                        endAdornment={
                                            !user && (<CircularProgress />)
                                        }
                                        {...field}
                                        id="fiscalRegime"
                                        label={t('userPage.billingDataFields.fiscalRegime')}
                                        labelId="fiscalRegimeLabel"
                                    >
                                        {
                                            FISCAL_REGIMES_LIST.map((regime, index) => (
                                                <MenuItem key={`fiscalRegime-${regime.value}-${index}`} value={regime.value}>{t(`fiscalRegimes.${regime.value}`)}</MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            }
                        />
                    </Grid>
                    <Grid item xs={4} md={4} xl={4}>
                        <Controller
                            control={control}
                            name={'billingAddress.streetName'}
                            render={({ field: { onChange, value, name } }) =>
                                <FormControl variant={'outlined'} fullWidth>
                                    <InputLabel htmlFor="streetName">{t('userPage.addressDataFields.streetName')}</InputLabel>
                                    <OutlinedInput
                                        endAdornment={
                                            !user && (<CircularProgress />)
                                        }
                                        id={'street'}
                                        label={t('userPage.addressDataFields.streetName')}
                                        name={name}
                                        onChange={(e) => onChange(e)}
                                        value={value}
                                    />
                                </FormControl>
                            }
                        />
                    </Grid>
                    <Grid item xs={2} md={2} xl={2}>
                        <Controller
                            control={control}
                            name={'billingAddress.streetNumExt'}
                            render={({ field: { onChange, value, name }  }) =>
                                <FormControl variant={'outlined'} fullWidth>
                                    <InputLabel htmlFor="streetNumberExt">{t('userPage.addressDataFields.streetNumberExt')}</InputLabel>
                                    <OutlinedInput
                                        endAdornment={
                                            !user && (<CircularProgress />)
                                        }
                                        id={'streetNumberExt'}
                                        label={t('userPage.addressDataFields.streetNumberExt')}
                                        name={name}
                                        onChange={(e) => onChange(e)}
                                        value={value}
                                    />
                                </FormControl>
                            }
                        />
                    </Grid>
                    <Grid item xs={2} md={2} xl={2}>
                        <Controller
                            control={control}
                            name={'billingAddress.streetNumInt'}
                            render={({ field: { onChange, value, name }  }) =>
                                <FormControl variant={'outlined'} fullWidth>
                                    <InputLabel htmlFor="streetNumberInt">{t('userPage.addressDataFields.streetNumberInt')}</InputLabel>
                                    <OutlinedInput
                                        endAdornment={
                                            !user && (<CircularProgress />)
                                        }
                                        id={'streetNumberInt'}
                                        label={t('userPage.addressDataFields.streetNumberInt')}
                                        name={name}
                                        onChange={(e) => onChange(e)}
                                        value={value}
                                    />
                                </FormControl>
                            }
                        />
                    </Grid>
                    <Grid item xs={4} md={4} xl={4}>
                        <Controller
                            control={control}
                            name={'billingAddress.neighborhood'}
                            render={({ field: { onChange, value, name }  }) =>
                                <FormControl variant={'outlined'} fullWidth>
                                    <InputLabel htmlFor="neighborhood">{t('userPage.addressDataFields.neighborhood')}</InputLabel>
                                    <OutlinedInput
                                        endAdornment={
                                            !user && (<CircularProgress />)
                                        }
                                        id={'neighborhood'}
                                        label={t('userPage.addressDataFields.neighborhood')}
                                        name={name}
                                        onChange={(e) => onChange(e)}
                                        value={value}
                                    />
                                </FormControl>
                            }
                        />
                    </Grid>
                    <Grid item xs={3} md={3} xl={3}>
                        <Controller
                            control={control}
                            name={'billingAddress.zipCode'}
                            render={({ field: { onChange, value, name }  }) =>
                                <FormControl variant={'outlined'} fullWidth>
                                    <InputLabel htmlFor="zipCode">{t('userPage.addressDataFields.zipCode')}</InputLabel>
                                    <OutlinedInput
                                        endAdornment={
                                            !user && (<CircularProgress />)
                                        }
                                        id={'zipCode'}
                                        label={t('userPage.addressDataFields.zipCode')}
                                        name={name}
                                        onChange={(e) => onChange(e)}
                                        value={value}
                                    />
                                </FormControl>
                            }
                        />
                    </Grid>
                    <Grid item xs={3} md={3} xl={3}>
                        <Controller
                            control={control}
                            name={'billingAddress.locality'}
                            render={({ field: { onChange, value, name }  }) =>
                                <FormControl variant={'outlined'} fullWidth>
                                    <InputLabel htmlFor="locality">{t('userPage.addressDataFields.locality')}</InputLabel>
                                    <OutlinedInput
                                        endAdornment={
                                            !user && (<CircularProgress />)
                                        }
                                        id={'locality'}
                                        label={t('userPage.addressDataFields.locality')}
                                        name={name}
                                        onChange={(e) => onChange(e)}
                                        value={value}
                                    />
                                </FormControl>
                            }
                        />
                    </Grid>
                    <Grid item xs={3} md={3} xl={3}>
                        <Controller
                            control={control}
                            name={'billingAddress.municipality'}
                            render={({ field: { onChange, value, name }  }) =>
                                <FormControl variant={'outlined'} fullWidth>
                                    <InputLabel htmlFor="municipality">{t('userPage.addressDataFields.municipality')}</InputLabel>
                                    <OutlinedInput
                                        endAdornment={
                                            !user && (<CircularProgress />)
                                        }
                                        id={'municipality'}
                                        label={t('userPage.addressDataFields.municipality')}
                                        name={name}
                                        onChange={(e) => onChange(e)}
                                        value={value}
                                    />
                                </FormControl>
                            }
                        />
                    </Grid>
                    <Grid item xs={3} md={3} xl={3}>
                        <Controller
                            control={control}
                            name={'billingAddress.state'}
                            render={({ field: { onChange, value, name }  }) =>
                                <FormControl variant={'outlined'} fullWidth>
                                    <InputLabel htmlFor="state">{t('userPage.addressDataFields.state')}</InputLabel>
                                    <OutlinedInput
                                        endAdornment={
                                            !user && (<CircularProgress />)
                                        }
                                        id={'state'}
                                        label={t('userPage.addressDataFields.state')}
                                        name={name}
                                        onChange={(e) => onChange(e)}
                                        value={value}
                                    />
                                </FormControl>
                            }
                        />
                    </Grid>
                </Grid>

                <StyledUserSubtitle>{t('userPage.adressData')}</StyledUserSubtitle>
                <Grid container justifySelf={'center'} sx={{ marginBottom: '2rem' }}>
                    {fields.map((field, index) => (
                        <ShippingAddressAccordion arrayName={'shippingAddresses'} control={control} field={field} id={field.id} index={index} remove={remove} />
                    ))}
                </Grid>

                <Grid container justifySelf={'center'} sx={{ marginBottom: '2rem' }}>
                    <Button 
                        onClick={() => append(EMPTY_ADDRESS_DATAFIELDS)}
                        variant="outlined"
                    >
                        {t('userPage.addShippingAddressButton')}
                    </Button>
                </Grid>

                <Grid container justifyContent={'end'} sx={{ marginBottom: '2rem' }}>
                    <Button 
                        type={'submit'}
                        variant="contained"
                    >
                        {t('userPage.submitButton')}
                    </Button>
                </Grid>
            </Box>
        </MainContainer>
    );
};