import { FC } from 'react';

import { styled } from '@mui/material/styles';
import { Copyright } from './Copyright';

const StyledFooter = styled('footer')(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    padding: '2rem'
}));

const StyledCopyright = styled(Copyright)(({ theme }) => ({
    color: theme.palette.secondary.main
}));

export const Footer: FC<{}> = () => {
    return (
        <StyledFooter>
            <StyledCopyright />
        </StyledFooter>
    );
};