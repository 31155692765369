import { FC, Fragment, useEffect } from "react";
import { useParams } from "react-router-dom";
import { styled } from '@mui/material/styles';

import { ProductDetails } from "../components/ProductDetails";

const MainContainer = styled('section')(({ theme }) => ({
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.black,
    padding: '1.75rem'
}));

export const ProductPage: FC<{}> = () => {
    let { productId } = useParams();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <Fragment>
            <MainContainer>
                <ProductDetails productId={productId as string} />
            </MainContainer>
        </Fragment>
    );
};
