import { ActionTypes, GlobalState } from "../types";

export const GlobalReducer = (state: GlobalState, action: any): GlobalState => {
    switch (action.type) {
        case ActionTypes.ADD_TO_CART: {
            return {
                ...state,
                cart: [ ...state.cart, action.product ]
            };
        }
        case ActionTypes.CHANGE_LANG: {
            return {
                ...state,
                lang: action.lang,
            };
        }
        case ActionTypes.EMPTY_CART: {
            return {
                ...state,
                cart: []
            }
        }
        default:
            throw Error("Unknown action: " + action.type);
    }
};
