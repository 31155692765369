import { FC } from 'react';

import { Typography } from '@mui/material';

export const Copyright: FC<any> = (props: any) => {
    return (
        <Typography variant="body2" align="center" {...props}>
            {'Copyright © '}
                Technohive
            {' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}