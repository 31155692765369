import { styled } from '@mui/material/styles';
import { FC } from "react";

import { LongProductPreview } from "./LongProductPreview";

const MainContainer = styled('div')(() => ({
    alignItems: "center",
    display: "grid",
    gridTemplateRows: "repeat(5, 1fr)",
    rowGap: "1rem",
    width: '100%'
}));

export const ProductGrid: FC<{ productList: any }> = ({ productList }) => {
    

    return (
        <MainContainer>
            {
                productList.map((product: any, index: number) => (
                    <LongProductPreview product={product} index={index} />
                ))
            }
        </MainContainer>
    );
};
