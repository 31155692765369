import { FC, useEffect, useState } from "react";

import {
    Button,
    CardMedia,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";

import {
    AttachMoney,
    ShoppingCart
} from "@mui/icons-material";

import { numberWithCommas } from "../utils";
import { getProduct } from "../api/firebase";
import { Loader } from "./Loader";
import { useTranslation } from "react-i18next";
import { useGlobal } from "../context/GlobalContext";
import { loadStripe } from "@stripe/stripe-js";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        fontWeight: 700
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        border: 'none'
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: '#D6D6D6',
    }
}));

const MainContainerStyle = (product: any) => {
    return {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column' as 'column',
        flexGrow: 1,
        height: product ? 'auto' : '100vh',
        justifyContent: 'space-between',
        position: 'relative' as 'relative',
        width: '100vw',
    };
};

const ProductDetailsViewerFirstHalfContainerStyle = styled('div')({
    display: "flex",
    height: "auto",
    justifyContent: "space-evenly",
    minWidth: "80rem",
});

const StyledCardMedia = styled(CardMedia)(({
    cursor: "pointer",
    height: "400px!important",
    width: "400px!important",
}));

const StyledBuyButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    fontSize: "1rem",
    fontWeight: 600,
    padding: "0.813rem",
    textTransform: "capitalize",
    width: "12.75rem",
    "&:hover": {
        backgroundColor: "#a1a1a1",
    },
}));

const StyledAddToCartButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.common.black,
    fontSize: "1rem",
    fontWeight: 600,
    padding: "0.813rem",
    textTransform: "capitalize",
    width: "12.75rem",
    "&:hover": {
        backgroundColor: "#a1a1a1",
    },
}));

const StyledProductTitle = styled(Typography)(({ theme }) => ({
    color: `${theme.palette.primary.main} !important`,
    fontFamily: `${theme.typography.fontFamily} !important`,
    fontSize: "2.5rem",
    fontWeight: `${600} !important`,
    marginBottom: '1.5rem'
}));

const StyledProductPrice = styled(Typography)(({ theme }) => ({
    fontFamily: `${theme.typography.fontFamily} !important`,
    fontWeight: 700,
    fontSize: '4.5rem',
}));

const TechSpecsTable: FC<{ product: any }> = ({ product }) => {
    const { t } = useTranslation('global');

    const formatDescription = (description: string) => {
        let formattedDescription = description.split('*');
        return (
            <>{
                formattedDescription.length > 1 ? (
                    <ul>
                        {
                            formattedDescription.map((option, index) => (
                                <li key={`description-option-${index}`}>{ option }</li>
                            ))
                        }
                    </ul>
                ) : (
                    <p>{description}</p>
                )
            }
            </>
        ); 
    };

    return (
        <Table sx={{ maxWidth: 1000 }} aria-label="customized table">
            <TableBody>
                {product.techSpecs.map((spec: any, index: number) => (
                    <StyledTableRow key={`${spec.name}-${index}`}>
                        <StyledTableCell component="th" scope="row" sx={{ fontWeight: '600' }}>{ t(`productProperties.${spec.name}`) }</StyledTableCell>
                        <StyledTableCell>{spec.description ? formatDescription(spec.description) : ''}</StyledTableCell>
                    </StyledTableRow>
                ))}
            </TableBody>
        </Table>
    );
};

interface ProductDetailsProps {
    productId: string;
}

interface ProductDetailsViewerProps {
    product: any;
}

const ProductDetailsViewer: FC<ProductDetailsViewerProps> = ({
    product
}) => {
    const { t } = useTranslation('global');
    const { addToCart } = useGlobal();
    
    const checkout = async () => {
        const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string);
        const stripeResult = await stripe?.redirectToCheckout({
            lineItems: [{
                price: `${product.stripeId}`,
                quantity: 1
            }],
            mode: 'payment',
            cancelUrl: window.location.origin,
            successUrl: `${window.location.origin}/succesful-payment`
        });

        if (stripeResult?.error) { console.log(stripeResult?.error); }
    };

    return (
        <>
            <ProductDetailsViewerFirstHalfContainerStyle>
                <StyledCardMedia
                    image={product ? product.thumbnail : ""}
                />
                <div style={{ minWidth: "30rem" }}>
                    <StyledProductTitle>{product?.name}</StyledProductTitle>
                    <StyledProductPrice>{`$ ${numberWithCommas(parseFloat(product.price))}`}</StyledProductPrice>
                    <div
                        style={{
                            alignItems: "center",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            height: "auto",
                        }}
                    >
                        <StyledBuyButton
                            onClick={() => checkout()}
                            variant={"contained"}
                            startIcon={<AttachMoney />}
                        >
                            { t('buttons.buyNowButton') }
                        </StyledBuyButton>
                        <StyledAddToCartButton
                            onClick={() => addToCart(product)}
                            variant={"contained"}
                            startIcon={<ShoppingCart />}
                        >
                            { t('buttons.addToCartButton') }
                        </StyledAddToCartButton>
                    </div>
                </div>
            </ProductDetailsViewerFirstHalfContainerStyle>
            <TechSpecsTable product={product} />
        </>
    );
};

export const ProductDetails: FC<ProductDetailsProps> = ({ productId }) => {
    const [product, setProduct] = useState<any | null>(null);
    const [open, setOpen] = useState(true);

    const handleBackdropClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        (async () => {
            const prod = await getProduct(productId);
            setProduct(prod);
        
            setOpen(false);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div style={MainContainerStyle(product)}>
            {product ? (
                <ProductDetailsViewer product={product} />
            ) : (
                <Loader open={open} handleBackdropClose={handleBackdropClose} />
            )}
        </div>
    );
};
