import i18n from "i18next";
import { FC, ReactNode } from 'react';
import { initReactI18next, I18nextProvider } from "react-i18next";

import global_en from '../translations/en/global.json';
import global_es from '../translations/es/global.json';
import { useGlobal } from "../context/GlobalContext";

interface LanguageProviderProps {
    children: ReactNode;
}

export const LanguageProvider: FC<LanguageProviderProps> = ({ children }) => {
    const { state } = useGlobal();

    i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: {
            es: {
                global: global_es
            },
            en: {
                global: global_en
            }
        },
        lng: state.lang,
        interpolation: { escapeValue: false }
    });

    return (
        <I18nextProvider i18n={i18n}>
            { children }
        </I18nextProvider>
    );
};
