import { onAuthStateChanged } from "@firebase/auth";
import { createContext, FC, useContext, useEffect, useState } from "react";

import { createUser, endSession, getUserByUID, signIn } from "../api/firebase";
import { auth } from "../firebase/firebase";

export const AuthContext = createContext({
    signUp: async (values: any) => { return },
    signOut: () => { return },
    logIn: (values: any) => { return },
    user: null
});

export const useAuth = () => {
    const auth = useContext(AuthContext);
    if (!auth) throw new Error("No Auth provider");
    return auth;
};

export const AuthProvider: FC<{ children: any }> = ({ children }) => {
    const [user, setUser] = useState<any>();
    const signUp = async (values: any) => {
        await createUser(values);
    };

    const logIn = (values: any) => {
        signIn(values);
    }

    const signOut = () => endSession();

    const getUserData = () => {
        onAuthStateChanged(auth, async (currentUser) => {
            if (currentUser) {
                const userData = await getUserByUID(currentUser.uid);
                setUser(userData);
            }
        });
    }

    useEffect(() => {
        getUserData();
    }, []);

    return (<AuthContext.Provider value={{ signUp, signOut, logIn, user }}>{children}</AuthContext.Provider>);
};
