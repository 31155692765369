import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { Controller, SubmitHandler, useForm } from "react-hook-form";

import { Button, CssBaseline, TextField, FormControlLabel, Checkbox, Grid, Box, Typography, Container } from '@mui/material';

import { getIcon } from '../api/firebase';
import { Copyright } from '../components/Copyright';
import { useAuth } from "../context/AuthContext";

interface SignUpPageFields {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
}

export const SignupPage: FC<{}> = () => {
    const { t } = useTranslation('global');
    const { signUp } = useAuth();
    const navigate = useNavigate();
    const { control, handleSubmit } = useForm<SignUpPageFields>({
        defaultValues: {
            firstName: '',
            lastName: '',
            email: '',
            password: ''
        }
    });

    const [logo, setLogo] = useState('');

    useEffect(() => {
        getIcon().then(data => setLogo(data.url));
    }, []);

    const onSubmit: SubmitHandler<SignUpPageFields> = (data) => {
        signUp(data);
        navigate('/');
    };

    return (
        <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center' }}>
            <Container
                component="main"
                maxWidth="xs"
                sx={(theme) => ({
                    backgroundColor: theme.palette.common.white,
                    borderRadius: '0.375rem',
                    boxShadow: '2px 2px 5px #d3d3d3',
                    padding: '1.5rem 1rem'
                })}
            >
                <CssBaseline />
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <img alt={'technohive-icon'} src={logo} height={160} width={160} />
                    <Typography component="h1" variant="h5">
                        {t('signUpPage.title')}
                    </Typography>
                    <Box component="form" noValidate onSubmit={handleSubmit(onSubmit)} sx={{ mt: 3 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <Controller
                                    control={control}
                                    name={'firstName'}
                                    render={({ field }) => 
                                        <TextField
                                            autoComplete="given-name"
                                            {...field}
                                            fullWidth
                                            label={t('signUpPage.firstNameField')}
                                            autoFocus
                                        />
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Controller
                                    control={control}
                                    name={'lastName'}
                                    render={({ field }) =>
                                        <TextField
                                            autoComplete="family-name"
                                            {...field}
                                            fullWidth
                                            label={t('signUpPage.lastNameField')}
                                        />
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name={'email'}
                                    render={({ field }) =>
                                        <TextField
                                            autoComplete="email"
                                            {...field}
                                            fullWidth
                                            id="email"
                                            label={t('signUpPage.emailField')}
                                        />
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name={'password'}
                                    render={({ field }) =>
                                        <TextField
                                            autoComplete="new-password"
                                            {...field}
                                            fullWidth
                                            label={t('signUpPage.passwordField')}
                                            type="password"
                                        />
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel
                                    control={<Checkbox value="allowExtraEmails" color="primary" />}
                                    label={t('signUpPage.wantToRecieveLabel')}
                                />
                            </Grid>
                        </Grid>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            {t('signUpPage.signUpButton')}
                        </Button>
                        <Grid container justifyContent="flex-end">
                            <Grid item>
                                <Link to="#">
                                    {t('signUpPage.alreadySignedUpLabel')}
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <Copyright sx={{ mt: 5 }} />
            </Container>
        </div>
    );
}