export enum ActionTypes {
    CHANGE_LANG,
    EMPTY_CART,
    ADD_TO_CART,
    REMOVE_FROM_CART,
    GET_CART_GRAND_TOTAL
}

export interface GlobalState {
    lang: string;
    cart: any[];
}
  
export interface GlobalContextType {
    state: GlobalState;
    addToCart: (product: any) => void;
    changeLanguage: (lang: string) => void;
    emptyCart: () => void;
    getCartTotalPrice: () => number;
}