export const numberWithCommas = (quantity: number): string => {
    return quantity
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  
export const createProductId = (product: any): string => {
    const formattedSeries = product.series.replace(/\s+/g, "");
    const formattedModel = product.model.replace(/\s+/g, "");
    return `${formattedSeries}${formattedModel}`;
};