import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { CartPage, LandingPage, LoginPage, ProductPage, CatalogPage, SignupPage, SuccesfulPaymentPage, UserPage } from "./pages";
import { TechnohiveThemeProvider } from "./style/theme";

import './App.css';
import { LanguageProvider } from './utils/i18n';
import { AuthProvider } from './context/AuthContext';
import { GlobalProvider } from './context/GlobalContext';
import { SnackbarProvider } from './context/SnackbarContext';

import { Footer } from './components/Footer';
import { Navbar } from "./components/Navbar";

function App() {
	return (
		<div className="App">
			<GlobalProvider>
				<SnackbarProvider>
					<AuthProvider>
						<LanguageProvider>
							<BrowserRouter>
								<TechnohiveThemeProvider>
									<Navbar />
									<Routes>
										<Route path="/" element={<LandingPage />} />
										<Route path="/login" element={<LoginPage />} />
										<Route path="/signup" element={<SignupPage />} />
										<Route path="/my-account" element={<UserPage />} />
										<Route path="/catalog" element={<CatalogPage />} />
										<Route path="/cart" element={<CartPage />} />
										<Route path="/succesful-payment" element={<SuccesfulPaymentPage />} />
										<Route path="/product/:productId" element={<ProductPage />} />
									</Routes>
									<Footer />
								</TechnohiveThemeProvider>
							</BrowserRouter>
						</LanguageProvider>
					</AuthProvider>
				</SnackbarProvider>
			</GlobalProvider>
		</div>
	);
}

export default App;
