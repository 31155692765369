import { Card, CardContent, CardMedia, Rating, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { numberWithCommas } from "../utils";
import { getProduct } from "../api/firebase";

const StyledCard = styled(Card)(({ theme }) => ({
    alignItems: "center",
    border: `0.063rem ${theme.palette.primary.main} solid`,
    display: "flex",
    flexDirection: "column",
    height: "17.188rem",
    padding: "1.125rem",
    width: "10.438rem",
    "&:hover": {
        boxShadow: `inset 0 1px 16px ${theme.palette.primary.main}`,
        cursor: 'pointer'
    },
}));

const StyledTypographyTitle = styled(Typography)(({ theme }) => ({
    color: theme.palette.primary.main,
    fontSize: "1.125rem",
    fontWeight: 600,
}));

const StyledTypographyPrice = styled(Typography)(({ theme }) => ({
    color: theme.palette.common.black,
    fontSize: "1rem",
    fontWeight: 400,
}));

export const ProductPreview: FC<{ productId: string }> = ({
    productId,
}) => {
    const navigate = useNavigate();
    const [product, setProduct] = useState<any>({});

    useEffect(() => {
        (async () => {
            const data = await getProduct(productId);
            setProduct(data);
        })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.preventDefault();
        navigate(`product/${productId}`);
    };

    return (
        <StyledCard onClick={handleClick}>
            <CardMedia
                alt="product-img"
                component="img"
                height="100"
                image={product.thumbnail}
                sx={{ objectFit: 'contain' }}
                width="100"
            />
            <CardContent sx={{ padding: 0, marginTop: '2.5rem' }}>
                <StyledTypographyTitle>{product.name}</StyledTypographyTitle>
                <StyledTypographyPrice>{`$ ${numberWithCommas(parseFloat(product.price))}`}</StyledTypographyPrice>
                <Rating value={product.rating || 0.0} precision={0.5} readOnly />
            </CardContent>
        </StyledCard>
    );
};
