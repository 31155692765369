import { createContext, FC, useCallback, useContext, useReducer } from "react";

import { GlobalReducer } from "./GlobalReducer";
import { ActionTypes, GlobalContextType, GlobalState } from "../types";

const initialState: GlobalState = {
    lang: 'es',
    cart: []
};

const initialCreateContext: GlobalContextType = {
    state: initialState,
    addToCart: (product: any) => {},
    changeLanguage: (lang: string) => {},
    emptyCart: () => {},
    getCartTotalPrice: () => { return 0 }
};

export const GlobalContext = createContext(initialCreateContext);

export const useGlobal = () => {
    const global = useContext(GlobalContext);
    if (!global) throw new Error("No Global provider");
    return global;
};

export const GlobalProvider: FC<{ children: any }> = ({ children }) => {
    const [state, dispatch] = useReducer(GlobalReducer, initialState);

    const changeLanguage = (lang: string) => {
        dispatch({ type: ActionTypes.CHANGE_LANG, lang: lang });
    };

    const addToCart = useCallback((product: any) => {
        dispatch({ type: ActionTypes.ADD_TO_CART, product: product });
      }, []);

    const emptyCart = () => {
        dispatch({ type: ActionTypes.EMPTY_CART });
    }

    const getCartTotalPrice = () => {
        return state.cart.reduce((accum, current) => accum = accum + (current.price * current.quantity));
    }

    return (
        <GlobalContext.Provider
            value={{
                state,
                addToCart,
                changeLanguage,
                emptyCart,
                getCartTotalPrice
            }}
        >
            {children}
        </GlobalContext.Provider>
    );
};
