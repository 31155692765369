import { FC, ReactNode } from 'react';

import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

interface PageArticleProps {
    children: ReactNode;
    title: string;
}

const StyledSectionTitle = styled(Typography)(({ theme }) => ({
    color: `${theme.palette.primary.main} !important`,
    fontFamily: `${theme.typography.fontFamily} !important`,
    fontSize: "2.5rem",
    fontWeight: `${600} !important`,
    marginBottom: '1.5rem',
    textAlign: 'start'
}));

const StyledArticle = styled('article')(() => ({
    alignSelf: 'center',
    height: 'auto',
    justifySelf: 'center'
}));

export const PageArticle: FC<PageArticleProps> = ({ children, title }) => {
    return (
        <StyledArticle>
            <StyledSectionTitle>
                {title}
            </StyledSectionTitle>
            {children}
        </StyledArticle>
    );
};