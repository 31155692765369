import { useState } from "react";

interface usePaginationState {
    currentPage: number;
}

const usePagination = (data: any, itemsPerPage: number) => {
    const [paginatedState, setPaginatedState] = useState<usePaginationState>({
        currentPage: 1
    });

    const maxPage = Math.ceil(data.length / itemsPerPage);

    const getPaginatedList = () => {
        const begin = (paginatedState.currentPage - 1) * itemsPerPage;
        const end = begin + itemsPerPage;
        return data.slice(begin, end);
    };

    const nextPage = () => {
        setPaginatedState((prevState) => ({
            ...prevState,
            currentPage: Math.min(prevState.currentPage + 1, maxPage)
        }));
    };

    const prevPage = () => {
        setPaginatedState((prevState) => ({
            ...prevState,
            currentPage: Math.max(prevState.currentPage - 1, 1)
        }));
    };

    const jumpToPage = (page: number) => {
        const pageNumber = Math.max(1, page);
        setPaginatedState((prevState) => ({
            ...prevState,
            currentPage: Math.min(pageNumber, maxPage)
        }));
    }

    return { nextPage, prevPage, jumpToPage, getPaginatedList, state: paginatedState, maxPage };
}

export default usePagination;