import { FC, useEffect, useState } from "react";

import { getSectionList } from '../api/firebase';
import { ProductPreview } from "./ProductPreview";

export const ProductSlider: FC<{ listName: string}> = ({ listName }) => {
    const [productList, setProductList] = useState<any>([]);
  
    useEffect(() => {
        getSectionList(listName).then(data => setProductList(data.products));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div
            style={{
                alignItems: "center",
                display: "grid",
                gridTemplateColumns: "repeat(7, 1fr)",
                gridGap: "3.125rem",
                overflowX: "scroll",
                maxWidth: "78.125rem",
            }}
        >
            {
                productList.map((product: string, index: string) => (
                    <ProductPreview key={index} productId={product} />
                ))
            }
        </div>
    );
};
