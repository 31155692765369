import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

import { IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';

import { FC } from 'react';
import { Fade } from 'react-slideshow-image';

import 'react-slideshow-image/dist/styles.css';
import '../slider-styles.css';

const StyledImageContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1rem 0',
    width: '100%'
});

export const ImageSlider: FC<{ images: string[] }> = ({ images }) => {
    return (
        <Fade
            cssClass='slider-wrapper'
            indicators
            nextArrow={<IconButton><KeyboardArrowRight /></IconButton>}
            prevArrow={<IconButton><KeyboardArrowLeft /></IconButton>}
        >
            {images.map((url: string, index: number) => (
                <StyledImageContainer key={`img-slider-item-container-${index}`}>
                    <img
                        alt={`slider-pic-${index}`}
                        key={index}
                        src={url}
                        style={{
                            height: 500,
                            display: 'block',
                            maxWidth: 1400,
                            overflow: 'hidden',
                            width: '100%',
                        }}
                    />
                </StyledImageContainer>
            ))}
        </Fade>
    );
};
