import { FC, Fragment, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';

import { ProductSlider } from '../components/ProductSlider';
import { PageArticle } from '../components/PageArticle';
import { useTranslation } from 'react-i18next';
import { ImageSlider } from '../components/ImageSlider';
import { getMainSliderImages } from '../api/firebase';

const MainContainer = styled('section')(({ theme }) => ({
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.black,
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridAutoRows: 'max-content',
    gridRowGap: '1rem',
    padding: '1.75rem'
}));

const StyedAboutUsText = styled('q')(({ theme }) => ({
    color: theme.palette.primary.main,
    fontSize: '1.25rem',
    fontStyle: 'italic'
}));

export const LandingPage: FC<{}> = () => {
    const { t } = useTranslation('global');
    const [images, setImages] = useState([]);

    useEffect(() => {
        (async () => {
            const data = await getMainSliderImages();
            setImages(data.urls);
        })();
    }, []);

    return (
        <Fragment>
            { images.length ? (<ImageSlider images={images} />) : null}
            <MainContainer>
                <PageArticle title={t('articleTitles.weekHighlights')}>
                    <ProductSlider listName={'featured'} />
                </PageArticle>
                <PageArticle title={t('articleTitles.aboutTechnohive')}>
                    <div style={{ maxWidth: "78.125rem" }}>
                        <StyedAboutUsText>
                            {t('articleText.aboutTechnohive')}
                        </StyedAboutUsText>
                    </div>
                </PageArticle>
            </MainContainer>
        </Fragment>
    );
}