import { Alert, AlertColor, Snackbar } from "@mui/material";
import { createContext, FC, useContext, useState } from "react";

interface SnackbarContextType {
    openSnackbar: (msg: string, sev: string) => void;
}

const initialCreateContext: SnackbarContextType = {
    openSnackbar: (msg: string, sev: string) => {}
};

export const SnackbarContext = createContext(initialCreateContext);

export const useSnackbar = () => {
    const snackbar = useContext(SnackbarContext);
    if (!snackbar) throw new Error("No Snackbar provider");
    return snackbar;
};

export const SnackbarProvider: FC<{ children: any }> = ({ children }) => {
    const [ snack, setSnack ] = useState({
        message: '',
        severity: '',
        open: false,
      });

    const openSnackbar = (msg: string, sev: string) => {
        setSnack({
            message: msg,
            severity: sev,
            open: true,
        });
    };
    
    const handleSnakcbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') { return; }
        setSnack((prevSnackState: any) => ({ ...prevSnackState, open: false }));
      };

    return (
        <SnackbarContext.Provider value={{ openSnackbar }}>
            <Snackbar open={snack.open} autoHideDuration={3000} onClose={handleSnakcbarClose}>
                <Alert elevation={6} severity={snack.severity as AlertColor} variant="filled">
                    {snack.message}
                </Alert>
            </Snackbar>
            { children }
        </SnackbarContext.Provider>
    );
};
