import { FC, ReactNode } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';

interface TechnohiveThemeProviderProps {
    children: ReactNode;
}

const TechnohiveTheme = createTheme({
    palette: {
        primary: {
          light: '#f4faff',
          main: '#1c252c'
        },
        secondary: {
          main: '#f6c800',
        },
    },
  });

export const TechnohiveThemeProvider: FC<TechnohiveThemeProviderProps> = ({ children }) => {
    return (
        <ThemeProvider theme={TechnohiveTheme}>
            { children }
        </ThemeProvider>
    );
};